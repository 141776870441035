import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";


Vue.config.productionTip = false
Vue.use(VueMeta)

Vue.use(VueGtag, {
  pageTrackerExcludedRoutes: [
    // 'route_path_value', 
    // 'route_name_value'
  ],
  config: { 
    id: "UA-166802319-1",
  },
}, router);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
