<template>
  <v-app>
    <v-app-bar
      app
      flat
      :height="$vuetify.breakpoint.xsOnly ? 64 : ''"
      dense
      :hide-on-scroll="$vuetify.breakpoint.smAndUp"
      id="pinoappbar"
      :extension-height="$vuetify.breakpoint.xsOnly ? 56 : 110"
      style="z-index: 2"
    >
      <v-toolbar
        color="primary"
        flat
        :height="$vuetify.breakpoint.xsOnly ? 64 : ''"
        dense
        dark
      >
        <v-toolbar-items>
          <v-btn
            :text="$vuetify.breakpoint.lgAndUp"
            :icon="$vuetify.breakpoint.mdAndDown"
            v-for="item in quickConnectBar"
            :key="item.title"
            :href="item.link"
            :target="item.target"
          >
            <v-icon :left="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
            <span class="hidden-md-and-down">{{ item.title }}</span>
          </v-btn>
        </v-toolbar-items>

        <v-spacer class="hidden-xs-only" />
        <v-toolbar-items>
          <v-btn
            icon
            href="https://www.facebook.com/rpinodental/"
            target="_blank"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            icon
            href="https://www.instagram.com/pinodental/"
            target="_blank"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <template v-slot:extension>
        <v-toolbar
          elevation="4"
          color="white"
          :height="$vuetify.breakpoint.xsOnly ? 56 : 110"
          extension-height="0"
        >
          <v-toolbar-title>
            <router-link to="/">
              <v-img
                alt="Pino Dental Logo"
                src="./assets/cropped-logo-h-1.png"
                :width="$vuetify.breakpoint.xsOnly ? 200 : 350"
                :height="$vuetify.breakpoint.xsOnly ? 40 : 70"
              />
            </router-link>
          </v-toolbar-title>
          <v-spacer />
          <v-app-bar-nav-icon
            class="hidden-lg-and-up tertiary lighten-1"
            @click="drawer = true"
          ></v-app-bar-nav-icon>
          <v-toolbar-items class="hidden-md-and-down">
            <v-menu left bottom open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  color="primary"
                  class="roboto-slab-button"
                  v-bind="attrs"
                  v-on="on"
                  active-class="link lighten-4 link--text"
                  to="/about"
                >
                  About
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  to="/about/about-pino-dental"
                  color="primary"
                  active-class="link lighten-4 link--text"
                >
                  <v-list-item-title>About Pino Dental</v-list-item-title>
                </v-list-item>
                <v-list-item
                  to="/about/meet-doctor-raquel-pino-and-staff"
                  color="primary"
                  active-class="link lighten-4 link--text"
                >
                  <v-list-item-title
                    >Meet the Doctor and Staff</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  to="/about/tour-the-office"
                  color="primary"
                  active-class="link lighten-4 link--text"
                >
                  <v-list-item-title>Tour the Office</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              to="/services"
              text
              color="primary"
              class="roboto-slab-button"
              active-class="link lighten-4 link--text"
            >
              Services
            </v-btn>
            <v-btn
              to="/affordable-solutions"
              text
              color="primary"
              class="roboto-slab-button"
              active-class="link lighten-4 link--text"
            >
              Affordable Solutions
            </v-btn>
            <v-btn
              to="/testimonials"
              text
              color="primary"
              class="roboto-slab-button"
              active-class="link lighten-4 link--text"
            >
              Testimonials
            </v-btn>
            <v-btn
              to="/contact-us"
              text
              color="primary"
              class="roboto-slab-button"
              active-class="link lighten-4 link--text"
            >
              Contact Us
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </template>
    </v-app-bar>
    <v-app-bar
      flat
      color="transparent"
      inverted-scroll
      style="z-index: 1; top: 46px"
      fixed
      extended
      height="72"
      :extension-height="$vuetify.breakpoint.smAndDown ? 72 : 64"
    >
      <template v-slot:extension>
        <v-container pa-0>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <v-toolbar flat color="transparent" elevation="2" id="pinoappbar">
                <v-toolbar-items>
                  <v-btn
                    to="/"
                    depressed
                    tile
                    color="primary"
                    class="roboto-slab-button-tiny"
                    :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                  >
                    <div class="d-flex flex-column">
                      <v-icon large>mdi-home-outline</v-icon>
                      <span class="hidden-sm-and-down">Homepage</span>
                    </div>
                  </v-btn>
                  <v-menu
                    v-model="beforevisitingmenu"
                    :close-on-content-click="false"
                    offset-y
                    bottom
                    tile
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        tile
                        color="secondary darken-2"
                        class="roboto-slab-button-tiny"
                        :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                      >
                        <div class="d-flex flex-column">
                          <v-icon large>mdi-file-sign</v-icon>
                          <span class="hidden-sm-and-down"
                            >Before Visiting</span
                          >
                        </div>
                      </v-btn>
                    </template>
                    <v-card tile width="400" color="secondary darken-2" dark>
                      <v-card-title class="text-h6 font-weight-bold">
                        Before Your Visit
                        <v-btn
                          class="ml-auto"
                          icon
                          @click="beforevisitingmenu = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        New to our practice? In order to help expedite your
                        appointment, please fill out these forms. Once
                        completed, please scan and email them to us or bring
                        them to your appointment. Remember to bring a picture ID
                        and your insurance card. Looking forward to fulfilling
                        your dental care needs!
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-0">
                        <v-btn
                          class="roboto-slab-button"
                          block
                          text
                          x-large
                          color="tertiary"
                          href="../assets/forms/1-english-form.pdf"
                          target="_blank"
                          >Download Forms
                          <v-icon right>mdi-file-pdf-box</v-icon></v-btn
                        >
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-actions class="pa-0">
                        <v-btn
                          class="roboto-slab-button"
                          block
                          text
                          x-large
                          color="tertiary"
                          href="../assets/forms/2-New-Patient-Form-Spanish.pdf"
                          target="_blank"
                          >Formas En Español
                          <v-icon right>mdi-file-pdf-box</v-icon></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>

                  <v-menu
                    v-model="locationmenu"
                    :close-on-content-click="false"
                    offset-y
                    bottom
                    tile
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        tile
                        color="secondary darken-1"
                        class="roboto-slab-button-tiny"
                        :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                      >
                        <div class="d-flex flex-column">
                          <v-icon large>mdi-map-marker-outline</v-icon>
                          <span class="hidden-sm-and-down">Location</span>
                        </div>
                      </v-btn>
                    </template>

                    <v-card tile width="400" color="secondary darken-1" dark>
                      <v-card-title class="text-h6 font-weight-bold">
                        Location
                        <v-btn
                          class="ml-auto"
                          icon
                          @click="locationmenu = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <strong> 20161 SW 127th AVE, Miami, FL 33177 </strong>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.7469191605287!2d-80.39785578498277!3d25.580085383719517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9c3842192e569%3A0xf59e22dd0291435!2sPino%20Dental!5e0!3m2!1sen!2sus!4v1585023943216!5m2!1sen!2sus"
                          width="100%"
                          height="250"
                          frameborder="0"
                          style="border: 0"
                          allowfullscreen
                        ></iframe>
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions class="pa-0">
                        <v-btn
                          class="roboto-slab-button"
                          block
                          text
                          x-large
                          color="tertiary"
                          href="https://goo.gl/maps/717TepTDaE4nbKQp8"
                          target="_blank"
                        >
                          Get Directions
                          <v-icon right>mdi-directions</v-icon></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>

                  <v-menu
                    v-model="hoursmenu"
                    :close-on-content-click="false"
                    offset-y
                    bottom
                    tile
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        tile
                        color="secondary"
                        class="roboto-slab-button-tiny"
                        :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                      >
                        <div class="d-flex flex-column">
                          <v-icon large>mdi-clock-outline</v-icon>
                          <span class="hidden-sm-and-down">Hours</span>
                        </div>
                      </v-btn>
                    </template>

                    <v-card tile width="400" color="secondary" dark>
                      <v-card-title class="text-h6 font-weight-bold">
                        Hours
                        <v-btn class="ml-auto" icon @click="hoursmenu = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list color="transparent" three-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >Monday-Thursday</v-list-item-title
                            >
                            <v-list-item-title>Friday</v-list-item-title>
                            <v-list-item-title>Saturday</v-list-item-title>
                            <v-list-item-title>Sunday</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content class="font-weight-black">
                            <v-list-item-title class="text-right">
                              9:00 AM - 5:00 PM
                            </v-list-item-title>
                            <v-list-item-title class="text-right">
                              9:00 AM - 12:00 PM
                            </v-list-item-title>
                            <v-list-item-title class="text-right">
                              Closed
                            </v-list-item-title>
                            <v-list-item-title class="text-right">
                              Closed
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>

                  <v-menu
                    v-model="qconnectmenu"
                    :close-on-content-click="false"
                    offset-y
                    bottom
                    tile
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        tile
                        color="secondary lighten-1"
                        class="roboto-slab-button-tiny"
                        :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                      >
                        <div class="d-flex flex-column">
                          <v-icon large>mdi-message-text-outline</v-icon>
                          <span class="hidden-sm-and-down">Quick Connect</span>
                        </div>
                      </v-btn>
                    </template>

                    <v-card tile width="400" color="secondary lighten-1" dark>
                      <v-card-title class="text-h6 font-weight-bold">
                        Quick Connect
                        <v-btn
                          class="ml-auto"
                          icon
                          @click="qconnectmenu = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          v-model="firstname"
                          label="First Name"
                          :rules="firstnameRules"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="lastname"
                          :rules="lastnameRules"
                          label="Last Name"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          solo
                          light
                        ></v-text-field>
                        <v-text-field
                          v-model="phonenumber"
                          :rules="phonenumberRules"
                          label="Phone"
                          solo
                          light
                        ></v-text-field>
                        <v-textarea
                          v-model="comments"
                          label="Comments"
                          solo
                          light
                        ></v-textarea>
                        <Captcha @verified="verified" />
                        <v-btn
                          color="secondary"
                          rounded
                          x-large
                          outlined
                          class="roboto-slab-button px-12"
                          :disabled="!validForm()"
                          @click="doEmail" 
                        >
                          Send Message
                        </v-btn>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-menu>

                  <v-btn
                    @click="toTop"
                    depressed
                    tile
                    color="primary"
                    class="roboto-slab-button-tiny"
                    :width="$vuetify.breakpoint.smAndDown ? '' : 130"
                  >
                    <div class="d-flex flex-column">
                      <v-icon large>mdi-arrow-up</v-icon>
                      <span class="hidden-sm-and-down">Top</span>
                    </div>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      color="tertiary lighten-1"
    >
      <v-list shaped color="tertiary lighten-1">
        <v-list-item two-line>
          <v-list-item-avatar
            color="white"
            size="70"
            tile
            class="elevation-3 rounded"
          >
            <v-avatar tile size="55"
              ><v-img src="@/assets/cropped-icon.png"></v-img
            ></v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 tertiary--text text--darken-4"
              >Main Menu</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          color="tertiary darken-4"
          v-for="item of sitemap"
          :key="item.text"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon color="tertiary darken-4">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="tertiary--text text--darken-4">{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <PDFooter />
    <v-snackbar v-model="snackbarSuccess">
      <v-icon color="success" left>mdi-mailbox-up</v-icon>
      Your messege has been sent

      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarFail">
      <v-icon color="error" left>mdi-mailbox-up</v-icon>Error your messege not
      sent

      <template v-slot:action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import PDFooter from '@/components/PDFooter.vue';
import Captcha from '@/components/Captcha.vue';
import { post } from '@/services/util';

export default {
  name: 'App',
  metaInfo: {
    title: 'Pino Dental',
    titleTemplate: '%s | Dr. Raquel Pino DMD',
    htmlAttrs: {
      lang: 'en',
      amp: true,
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        property: 'og:title',
        content: 'Pino Dental',
        template: (chunk) => `${chunk} - Dr. Raquel Pino DMD`,
        vmid: 'og:title',
      },
    ],
  },
  components: {
    PDFooter,
    Captcha,
  },
  data: () => ({
    //connect bar
    quickConnectBar: [
      {
        title: 'Dr. Raquel Pino DMD',
        icon: 'mdi-doctor',
        link: '#/about/meet-doctor-raquel-pino-and-staff',
        target: '_self',
      },
      {
        title: '305-709-1090',
        icon: 'mdi-phone',
        link: 'tel:3057091090',
        target: '_self',
      },
      {
        title: '786-384-6442',
        icon: 'mdi-message-text',
        link: 'sms:+17863846442?&body=I%20am%20sending%20a%20text%20message%20from%20PinoDental.com.%20Let%27s%20connect...',
        target: '_self',
      },
      {
        title: '20161 SW 127th AVE, Miami, FL 33177',
        icon: 'mdi-map-marker',
        link: 'https://goo.gl/maps/717TepTDaE4nbKQp8',
        target: '_blank',
      },
      {
        title: 'info@pinodental.com',
        icon: 'mdi-email',
        link: 'mailto:info@pinodental.com',
        target: '_self',
      },
    ],
    beforevisitingmenu: false,
    locationmenu: false,
    hoursmenu: false,
    qconnectmenu: false,
    drawer: false,
    sitemap: [
      {
        text: 'Home',
        route: '/',
        icon: 'mdi-home-variant-outline',
      },
      {
        text: 'About Pino Dental',
        route: '/about/about-pino-dental',
        icon: 'mdi-information-outline',
      },
      {
        text: 'Meet the Doctor and Staff',
        route: '/about/meet-doctor-raquel-pino-and-staff',
        icon: 'mdi-doctor',
      },
      {
        text: 'Tour the Office',
        route: '/about/tour-the-office',
        icon: 'mdi-chair-rolling',
      },
      {
        text: 'Services',
        route: '/services',
        icon: 'mdi-toothbrush-paste',
      },
      {
        text: 'Affordable Solutions',
        route: '/affordable-solutions',
        icon: 'mdi-cash',
      },
      {
        text: 'Testimonials',
        route: '/testimonials',
        icon: 'mdi-comment-quote-outline',
      },
      {
        text: 'Contact Us',
        route: '/contact-us',
        icon: 'mdi-forum',
      },
    ],
    valid: false,
    firstname: '',
    firstnameRules: [
      (v) => !!v || 'First Name is required',
      (v) =>
        (v && v.length <= 20) || 'First Name must be less than 20 characters',
    ],
    lastname: '',
    lastnameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 20) || 'Last Name must be less than 20 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phonenumber: '',
    phonenumberRules: [
      (v) => !!v || 'Phone number is required',
      // (v) => /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(v) || "Phone Number must be valid",
    ],
    comments: '',
    captcha_ver: true,
    captcha_token: '',
    snackbarSuccess: false,
    snackbarFail: false,
  }),
  methods: {
    toTop() {
      this.$vuetify.goTo(0);
    },
    doEmail() {
      post(
        '/api/mail',
        {
          firstname: this.firstname,
          lastname: this.lastname,
          comments: this.comments,
          phonenumber: this.phonenumber,
          email: this.email,
        },
        { 'captcha-token': this.captcha_token }
      )
        .then(() => {
          this.firstname = '';
          this.lastname = '';
          this.comments = '';
          this.phonenumber = '';
          this.email = '';
          this.snackbarSuccess = true;
          this.captcha_ver = true;
        })
        .catch((e) => {
          this.snackbarFail = true;
          console.log(e);
        });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if (this.captcha_ver === true) {
        return false;
      }
      return this.valid;
    },
  },
};
</script>
