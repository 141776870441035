var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4","order":"5","order-md":"1"}},[_c('v-card',{attrs:{"color":"light","flat":"","tile":"","min-height":"250"}},[_c('v-carousel',{attrs:{"cycle":"","vertical":"","hide-delimiters":"","show-arrows":false,"height":"auto"}},[_c('v-carousel-item',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card-text',{staticClass:"d-flex flex-column text-center"},[_c('v-icon',{attrs:{"color":"primary","size":hover ? 24 : 72}},[_vm._v(" mdi-face-woman-shimmer ")]),_c('h6',{staticClass:"text-h6 my-3"},[_vm._v("Cosmetic Dentistry")]),_c('p',{staticClass:"dark--text"},[_vm._v(" Dr. Pino is excited to help you achieve the smile of your dreams! Veneers, crowns or teeth bleaching can help enhance your smile. ")]),_c('v-expand-transition',[(hover)?_c('v-btn',{attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-carousel-item',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card-text',{staticClass:"d-flex flex-column text-center"},[_c('v-icon',{attrs:{"color":"primary","size":hover ? 24 : 72}},[_vm._v(" mdi-tooth-outline ")]),_c('h6',{staticClass:"text-h6 my-3"},[_vm._v("Invisible Braces")]),_c('p',{staticClass:"dark--text"},[_vm._v(" Dr. Pino is an Invisalign certified dentist! Ask about a clear alternative to metal braces for kids, teens, and adults. ")]),_c('v-expand-transition',[(hover)?_c('v-btn',{attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8","order":"2","order-md":"2"}},[_c('v-card',{attrs:{"color":"light","flat":"","tile":"","height":_vm.$vuetify.breakpoint.xsOnly ? 350 : 250}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{attrs:{"position":hover ? 'center top' : 'center bottom',"height":"100%","src":require('@/assets/service-02.png')}},[_c('div',{class:[
                hover ? ' bottom-gradient' : 'bottom-gradient2',
                'fill-height',
                'align-center' ]},[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"7","sm":"6"}},[_c('h6',{staticClass:"text-h6 my-3"},[_vm._v("Crown and Bridges")]),_c('p',{staticClass:"dark--text"},[_vm._v(" Dr. Pino can help reconstruct your teeth or help replace them in a variety of ways, including fixed dental bridges. ")]),_c('v-btn',{staticClass:"white",attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1)],1)],1)],1)],1)])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"8","order":"3","order-md":"3"}},[_c('v-card',{attrs:{"color":"light","flat":"","tile":"","height":_vm.$vuetify.breakpoint.xsOnly ? 350 : 250}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-img',{attrs:{"position":hover ? 'center top' : 'center bottom',"height":"100%","src":require('@/assets/service-04.png')}},[_c('div',{class:[
                hover ? ' bottom-gradient' : 'bottom-gradient2',
                'fill-height',
                'align-center' ]},[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"7","sm":"6"}},[_c('h6',{staticClass:"text-h6 my-3"},[_vm._v(" Kid's Dentistry")]),_c('p',{staticClass:"dark--text"},[_vm._v(" Dr. Pino loves treating children of all ages. Learning good oral habits from an early age can prevent many oral diseases as an adult. ")]),_c('v-btn',{staticClass:"white",attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1)],1)],1)],1)],1)])]}}])})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","order":"4","order-md":"4"}},[_c('v-card',{attrs:{"color":"light","flat":"","tile":"","min-height":"250"}},[_c('v-carousel',{attrs:{"cycle":"","vertical":"","hide-delimiters":"","show-arrows":false,"height":"auto"}},[_c('v-carousel-item',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card-text',{staticClass:"d-flex flex-column text-center"},[_c('v-icon',{attrs:{"color":"primary","size":hover ? 24 : 72}},[_vm._v(" mdi-tooth-outline ")]),_c('h6',{staticClass:"text-h6 my-3"},[_vm._v("Dental Implants")]),_c('p',{staticClass:"dark--text"},[_vm._v(" If teeth are lost because of disease or an accident, Pino Dental can place a replacement tooth root made of titanium, also known as dental implants. ")]),_c('v-expand-transition',[(hover)?_c('v-btn',{attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1):_vm._e()],1)],1)]}}])})],1),_c('v-carousel-item',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card-text',{staticClass:"d-flex flex-column text-center"},[_c('v-icon',{attrs:{"color":"primary","size":hover ? 24 : 72}},[_vm._v(" mdi-car-emergency ")]),_c('h6',{staticClass:"text-h6 my-3"},[_vm._v("Emergency")]),_c('p',{staticClass:"dark--text"},[_vm._v(" For a dental emergency, where same-day treatment is necessary, we strive to help you. For medical emergencies always dial 911. ")]),_c('v-expand-transition',[(hover)?_c('v-btn',{attrs:{"color":"secondary","rounded":"","outlined":"","large":""}},[_vm._v(" Learn more "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-hand-pointing-right")])],1):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }