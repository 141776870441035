<template>
  <div>
    <HeroSlider />
    <Welcome />
    <RowHeader
      :pretext="'Dentistry We Offer'"
      :title="'General Dentist Services by Pino Dental'"
    />
    <ServiceGrid />
    <RowHeader :pretext="'For Our Dear Patients'" :title="'Special Offers'" />
    <Offers />
    <PatientTestimonials />
    <Experience />
    <GMap />
    <Partners />
  </div>
</template>

<script>
import HeroSlider from '@/components/HeroSlider.vue';
import Welcome from '@/components/Welcome.vue';
import RowHeader from '@/components/RowHeader.vue';
import ServiceGrid from '@/components/ServiceGrid.vue';
import PatientTestimonials from '@/components/PatientTestimonials.vue';
import Offers from '@/components/Offers.vue';
import Experience from '@/components/Experience.vue';
import GMap from '@/components/GMap.vue';
import Partners from '@/components/Partners.vue';

export default {
  name: 'Home',
  metaInfo: {
    title: 'Pino Dental Serving Quail Roost, South Miami Heights, West Perrine',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Pino Dental advanced dentist treats, root canals, extractions, fillings, crowns, implants, Invisalign, Botox, fillers, and more.',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, dentist office, dentist appointment, orthodontist, dental hygienist',
      },
    ],
  },
  components: {
    HeroSlider,
    Welcome,
    RowHeader,
    ServiceGrid,
    PatientTestimonials,
    Offers,
    Experience,
    GMap,
    Partners,
  },
};
</script>
