<template>
  <v-container mb-12>
    <v-row>
      <v-col cols="12" md="6" v-for="item of offer" :key="item.title">
        <v-card :img="require('../assets/' + item.img)">
          <v-container>
            <v-row justify="end">
              <v-col cols="6">
                <div class="text-overline text-right link--text">
                  {{ item.overline }}
                </div>

                <v-card-title
                  primary-title
                  class="text-h5 primary--text font-weight-bold"
                >
                  {{ item.title }}
                </v-card-title>
                <v-card-subtitle class="text-h6 font-weight-bold darker--text">
                  {{ item.offer }}
                </v-card-subtitle>
                <v-card-text>
                  {{ item.desc }}
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" outlined rounded class="px-6">
                    {{ item.ctatxt }}
                    <v-icon right>mdi-hand-pointing-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Offers',
  data: () => ({
    offer: [
      {
        img: 'special-photo-01.jpg',
        overline: 'New',
        title: 'New Patient',
        offer: '$69 Special',
        desc: 'Exam & Xrays',
        ctatxt: 'Contact Us',
        ctaurl: '',
      },
      {
        img: 'special-photo-02.jpg',
        overline: 'Ending Soon',
        title: 'Dental Implant',
        offer: '$2,750',
        desc: 'Crown Included',
        ctatxt: 'Contact Us',
        ctaurl: '',
      },
      {
        img: 'special-photo-03.jpg',
        overline: 'Limited Time',
        title: 'Clear Aligners',
        offer: '$3,500',
        desc: 'or $145 per month.',
        ctatxt: 'Contact Us',
        ctaurl: '',
      },
      {
        img: 'special-photo-04.jpg',
        overline: 'New',
        title: 'Smile Design',
        offer: 'Starts at $3,800',
        desc: 'in just one visit',
        ctatxt: 'Contact Us',
        ctaurl: '',
      },
    ],
  }),
};
</script>
