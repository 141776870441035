import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#89ba28',
            secondary: '#33a6d7',
            tertiary:'#faca96',
            accent: '#33c2d7',
            link: '#1e76bd',

            success:'#3F612D',
            info:'#FEC9F1',
            warning:'#FC9F5B',
            error:'#DA3E52',

            darker: '#444444',
            dark:'#787878',
            light:'#f7f7f7'
            

          },
        },
      },
});
