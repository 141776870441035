<template>
  <vue-recaptcha
    sitekey="6LdL_aUgAAAAAJPrNUplwhjERAEe0IBppt4YPpdz"
    @verify="onVerify"
  ></vue-recaptcha>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  methods: {
    recaptcha() {
      this.$recaptcha("login").then((token) => {
        console.log(token); // Will print the token
      })
    },
    onVerify (response) {
      this.$emit("verified", response);
    }
   },
  components: {
    VueRecaptcha
  }
};
</script>
