<template>
  <v-container class="my-10">
    <v-row justify="center">
      <v-col cols="10" class="d-flex flex-column text-center">
        <h3 class="text-h6 text-lg-h6 font-weight-medium darker--text">
          {{ title }}
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-chip class="px-12" color="primary" x-small> </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'RowHeader',
  props: ['pretext', 'title'],
  data() {
    return {};
  },
};
</script>
