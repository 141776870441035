<template>
  <v-container>
    <RowHeader :pretext="'What People Says'" :title="'Testimonials'" />
    <v-row justify="center">
      <v-col cols="12" md="8" class="d-flex flex-column align-center">
        <div class="text-h1 font-weight-black primary--text">5</div>
        <v-rating
          v-model="rating"
          background-color="purple lighten-3"
          color="primary"
          large
          readonly
        ></v-rating>
        <h4 class="darker--text text-h4 font-weight-bold mb-6">
          Average <span class="primary--text">Customer Rating</span>
        </h4>
        <p>
          Please take a look below at some of the recent testimonials we have
          received from our patients. We welcome patient feedback to let us know
          how we can improve – please send us details of your experience.
        </p>
        <v-btn
          color="secondary"
          rounded
          outlined
          large
          class="roboto-slab-button"
        >
          Write Your Review
          <v-icon right>mdi-hand-pointing-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(item, i) of reviews" :key="i">
        <v-card
          :color="item.color"
          :dark="item.dark"
          elevation="6"
          height="100%"
          class="d-flex flex-column align-center pa-6 rounded-xl"
        >
          <v-avatar color="primary" size="72" class="elevation-3 mx-auto">
            <v-icon dark x-large> mdi-tooth </v-icon>
          </v-avatar>
          <v-card-title class="text-h6 text-center">
            {{ item.service }}
          </v-card-title>
          <v-rating
            :value="item.rate"
            background-color="white"
            color="primary"
            large
            readonly
          />
          <p>{{ item.quote }}</p>
          <p>
            - {{ item.name }},
            <span class="primary--text"> {{ item.title }} </span>
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RowHeader from '@/components/RowHeader.vue';

export default {
  name: 'Testimonials',
  metaInfo: {
    title:
      'Patients testimonials about Pino Dental',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "Read reviews what real patients are saying about Dr. Raquel Pino at Pino Dental in Miami",
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, experience with dentist, reviews for endodontist, dentist reviews, orthodontist testimonials, dental visit reviews, dentist experience review ',
      },
    ],
  },
  components: {
    RowHeader,
  },
  data: () => ({
    rating: 5,
    reviews: [
      {
        pic: 'pic',
        service: 'Teeth extraction',
        rate: 5,
        quote:
          'I always go see Dr. Pino. She is the best. She takes her time explaining all treatment options and makes sure you understand everything.',
        name: 'Paulina',
        title: 'The Crossings, Miami, FL',
        color: 'tertiary lighten-1',
        dark: false,
      },
      {
        pic: 'pic',
        service: 'Teeth Whitening',
        rate: 5,
        quote:
          'Dr Pino and her team are amazing. They explained in detail the different options I had with my dental problem. They tried to accommodate all my concerns and made me feel like family.',
        name: 'Rosi',
        title: 'The Roads, Miami, FL',
        color: 'secondary',
        dark: true,
      },
      {
        pic: 'pic',
        service: 'Invisible Braces',
        rate: 5,
        quote:
          'Highly Recommend. This was the best dental office I’ve ever been to. The staff was so nice and helpful. Dr. Pino was incredible, explained everything, ensured I was comfortable.',
        name: 'Melissa',
        title: 'The Crossings, Miami, FL',
        color: 'light',
        dark: false,
      },
      {
        pic: 'pic',
        service: 'Invisible Braces',
        rate: 5,
        quote:
          'Pino dental is amazing! Everything is clean and beautiful. Awesome and up to date technology used throughout the office and the customer service is top tier. From the minute you walk in to the minute you leave. Everyone communicates great with each other and are all on the same page as to what step is next in your process. I hate dentist offices in general but coming here is always a great experience. Dr. Pino is amazing, she thoroughly explains everything in at that makes sense and Laura is an amazing hygienist, she is gentle and explains what is going on at all times! I highly recommend this office!',
        name: 'Rebecca Rodriguez',
        title: 'Miami, FL',
        color: 'darker',
        dark: true,
      },
      {
        pic: 'pic',
        service: 'Invisible Braces',
        rate: 5,
        quote:
          'Before coming to Pino dental I was not confident about my smile and the way people used to looked at me but after meeting these beautiful and talented people they have changed the way I smile and I became more confident. They understood me, they were very patient, the staff is lovely BEST OF THE BEST, and they welcomed me with open arms. Strongly advised anyone to go with PINO DENTAL.',
        name: 'Priscila Marques',
        title: 'Miami, FL',
        color: 'primary lighten-2',
        dark: false,
      },
    ],
  }),
};
</script>
