<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="11" md="6">
        <h3 class="darker--text mb-6">
          <span class="d-block">Pino Dental </span>
          For Patients of ALL Ages in a Brand New Facility
        </h3>
        <h1 class="darker--text text-h3">
          Welcome to <span class="primary--text">Pino Dental</span>
        </h1>
        <h5 class="my-6 text-h6 darker--text">Message from the Doctor</h5>
        <p class="text-justify">
          Welcome to our practice. I am delighted to be contributing to your
          oral health. In the planning stages of creating this office, I
          envisioned creating a warm, comforting, patient-centered practice
          where the patient had a reliably amazing experience each and every
          time you visit. Gone are the days of fear and anxiety associated with
          the dentist. By utilizing nitrous gas, oral sedation, and very
          powerful numbing gel, you can expect a painless and worry-free
          procedure. By providing weighted blankets, Bluetooth headphones, and
          smart HDTV’s in the ceiling of every treatment room, you can relax
          comfortably while getting your much-needed dental care. Looking
          forward to meeting each and every one of you.
        </p>
        <p class="text-center">
          To learn more or request an appointment, click below:
        </p>
        <v-container>
          <v-row justify="center">
            <v-col cols="auto">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    color="secondary"
                    rounded
                    class="roboto-slab-button"
                  >
                    Request an Appointment
                    <v-icon right>mdi-hand-pointing-right</v-icon>
                  </v-btn>
                </template>

                <v-card color="secondary">
                  <v-card-title class="text-h5 secondary">
                   Request An Appointment
                  </v-card-title>

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                      <v-row dense justify="center">
                        <v-col cols="6">
                          <v-text-field
                            v-model="firstname"
                            label="First Name"
                            :rules="firstnameRules"
                            solo
                            shaped
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="lastname"
                            :rules="lastnameRules"
                            label="Last Name"
                            solo
                            shaped
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="email"
                            :rules="emailRules"
                            label="Email"
                            solo
                            shaped
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="phonenumber"
                            :rules="phonenumberRules"
                            label="Phone"
                            solo
                            shaped
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="comments"
                            label="Condition / Request"
                            solo
                            shaped
                          ></v-textarea>
                        </v-col>
                        <v-col cols="auto">
                          <Captcha @verified="verified" />
                        </v-col>
                        <v-col>
                          <v-btn
                            color="white"
                            rounded
                            x-large
                            outlined
                            class="roboto-slab-button px-12"
                            :disabled="!validForm()"
                            @click="doEmail"
                            block
                          >
                            Send Message
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-snackbar v-model="snackbarSuccess">
                        <v-icon color="success" left>mdi-mailbox-up</v-icon>
                        Your messege has been sent

                        <template v-slot:action="{ attrs }">
                          <v-btn
                            color="success"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                          >
                            Close
                          </v-btn>
                        </template>
                      </v-snackbar>
                      <v-snackbar v-model="snackbarFail">
                        <v-icon color="error" left>mdi-mailbox-up</v-icon>Error
                        your messege not sent

                        <template v-slot:action="{ attrs }">
                          <v-btn
                            color="error"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                          >
                            Close
                          </v-btn>
                        </template>
                      </v-snackbar>
                    </v-container>
                  </v-form>

                 
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn
                text
                color="secondary"
                rounded
                class="roboto-slab-button"
                to="/about/meet-doctor-raquel-pino-and-staff"
              >
                Meet the Doctor
                <v-icon right>mdi-hand-pointing-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <h5 class="my-6 text-h6 darker--text">About Our Location</h5>
        <p class="text-justify">
          Conveniently located in the Shoppes at Quail Roost, we are situated in
          the same shopping center as Publix, YouFit, and Bank of America. Our
          location offers ample FREE parking. Our ideal location in South
          Miami-Dade County places us centrally amongst your daily shopping and
          brings premiere dental care close to where you live and work. If you
          have any question on how to locate our dental office please call us at
          <a href="tel:3057091090">(305) 709-1090</a>
        </p>
      </v-col>
      <v-col cols="8" sm="6" md="6" align-self="end">
        <v-img :src="require('@/assets/ptofile-photo-transparent.png')" />
      </v-col>
    </v-row>
    <v-divider />
  </v-container>
</template>
<script>
import Captcha from '../components/Captcha.vue';
import { post } from '../services/util';

export default {
  name: 'ContactUs',
  components: {
    Captcha,
  },
  data: () => ({
    dialog: false,
    valid: false,
    firstname: '',
    firstnameRules: [
      (v) => !!v || 'First Name is required',
      (v) =>
        (v && v.length <= 20) || 'First Name must be less than 20 characters',
    ],
    lastname: '',
    lastnameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 20) || 'Last Name must be less than 20 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phonenumber: '',
    phonenumberRules: [
      (v) => !!v || 'Phone number is required',
      // (v) => /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(v) || "Phone Number must be valid",
    ],
    comments: '',
    captcha_ver: true,
    captcha_token: '',
    snackbarSuccess: false,
    snackbarFail: false,
  }),
  methods: {
    doEmail() {
      post(
        '/api/mail',
        {
          firstname: this.firstname,
          lastname: this.lastname,
          comments: this.comments,
          phonenumber: this.phonenumber,
          email: this.email,
        },
        { 'captcha-token': this.captcha_token }
      )
        .then(() => {
          this.firstname = '';
          this.lastname = '';
          this.comments = '';
          this.phonenumber = '';
          this.email = '';
          this.snackbarSuccess = true;
          this.captcha_ver = true;
        })
        .catch((e) => {
          this.snackbarFail = true;
          console.log(e);
        });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if (this.captcha_ver === true) {
        return false;
      }
      return this.valid;
    },
  },
};
</script>
