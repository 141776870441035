<template>
  <div>
    <GMap />
    <v-container fluid>
      <v-row class="light">
        <v-col>
          <RowSubHeader :title="'Clinic Location'" />
          <v-list color="transparent" three-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-map-marker </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >Pino Dental</v-list-item-title
                >
                <v-list-item-title>20161 SW 127th AVE</v-list-item-title>
                <v-list-item-subtitle>Miami, FL 33177</v-list-item-subtitle>
                <v-list-item-subtitle
                  ><a
                    href="https://goo.gl/maps/717TepTDaE4nbKQp8"
                    target="_blank"
                    >Directions</a
                  ></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <RowSubHeader :title="'Contact Us'" />
          <v-list color="transparent" two-line>
            <v-list-item to="tel:3057091090">
              <v-list-item-icon>
                <v-icon color="primary"> mdi-phone </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>(305) 709-1090</v-list-item-title>
                <v-list-item-subtitle>Receptionist</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              to="sms:+17863846442?&body=I%20am%20sending%20a%20text%20message%20from%20PinoDental.com.%20Let%27s%20connect..."
            >
              <v-list-item-icon>
                <v-icon color="primary"> mdi-message-text </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>(786) 384-6442</v-list-item-title>
                <v-list-item-subtitle>Text Message</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="mailto:info@pinodental.com">
              <v-list-item-icon>
                <v-icon color="primary"> mdi-email </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>info@pinodental.com</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <RowSubHeader :title="'Our Hours'" />
          <v-list color="transparent" three-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-clock-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Monday-Thursday</v-list-item-title>
                <v-list-item-title>Friday</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content class="primary--text font-weight-black">
                <v-list-item-title>9:00 AM - 5:00 PM</v-list-item-title>
                <v-list-item-title>9:00 AM - 12:00 PM</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <RowHeader :pretext="''" :title="'Contact Us'" />
      <v-row>
        <v-col md="5">
          <h5 class="my-6 text-h6 darker--text">
            Walk-ins are always welcome!
          </h5>

          <p>
            To make an appointment or if you have any questions, please complete
            this form and a member of our staff will contact you as soon as
            possible. You can also contact a dentist at (305) 709-1090. If you
            would like to speak in person, walk-ins are always welcome. We look
            forward to hearing from you!
          </p>
          <h5 class="my-6 text-h6 darker--text">Hablamos Español</h5>

          <p>
            Para hacer una cita o si tiene alguna pregunta, complete este
            formulario y un miembro de nuestro personal se comunicará con usted
            lo antes posible. También puede llamarnos al (305) 709-1090. Si
            desea hablar en persona, siempre se aceptan personas sin cita
            previa. ¡Esperamos con interés escuchar de usted!
          </p>
        </v-col>
        <v-col>
          <v-container>
            <v-row justify="center">
              <v-col md="8">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container>
                    <v-row dense>
                      <v-col cols="6">
                        <v-text-field
                          v-model="firstname"
                          label="First Name"
                          :rules="firstnameRules"
                          filled
                          shaped
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="lastname"
                          :rules="lastnameRules"
                          label="Last Name"
                          filled
                          shaped
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          filled
                          shaped
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field
                          v-model="phonenumber"
                          :rules="phonenumberRules"
                          label="Phone"
                          filled
                          shaped
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="comments"
                          label="Comments"
                          filled
                          shaped
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <Captcha @verified="verified" />
                      </v-col>
                      <v-col>
                        <v-btn
                          color="secondary"
                          rounded
                          x-large
                          outlined
                          class="roboto-slab-button px-12"
                          :disabled="!validForm()"
                          @click="doEmail" 
                        >
                          Send Message
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-snackbar v-model="snackbarSuccess">
                      <v-icon color="success" left>mdi-mailbox-up</v-icon>
                      Your messege has been sent

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="success"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                    <v-snackbar v-model="snackbarFail">
                      <v-icon color="error" left>mdi-mailbox-up</v-icon>Error
                      your messege not sent

                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="error"
                          text
                          v-bind="attrs"
                          @click="snackbar = false"
                        >
                          Close
                        </v-btn>
                      </template>
                    </v-snackbar>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <RowSubHeader :title="'Meet the Dentist'" />
          <v-img
            aspect-ratio="1"
            :src="require('@/assets/contact-raquel-pino-dentist.jpeg')"
            class="rounded-pill"
            position="center top"
            style="border: 5px solid #89ba28"
          />
          <v-btn color="link" block text class="roboto-slab-button"
            >Meet the Dentist</v-btn
          >
        </v-col>
        <v-col>
          <RowSubHeader :title="'Stay Connected'" />
          <v-list nav color="transparent" class="px-10">
            <v-list-item
              color="primary"
              v-for="item of connect"
              :key="item.text"
              :href="item.route"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon color="primary">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-card color="light" class="mt-12 pa-6">
            <v-card-title class="text-h4 font-weight-bold">
              Before Your Visit
            </v-card-title>
            <v-card-text>
              New to our practice? In order to help expedite your appointment,
              please fill out these forms. Once completed, please scan and email
              them to us or bring them to your appointment. Remember to bring a
              picture ID and your insurance card. Looking forward to fulfilling
              your dental care needs!
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="roboto-slab-button"
                block
                text
                large
                color="error"
                href="../assets/forms/1-english-form.pdf"
                target="_blank"
                >Download Forms
                <v-icon right large>mdi-file-pdf-box</v-icon></v-btn
              >
            </v-card-actions>
            <v-card-actions>
              <v-btn
                class="roboto-slab-button"
                block
                text
                large
                color="error"
                href="../assets/forms/2-New-Patient-Form-Spanish.pdf"
                target="_blank"
              >
                Formas en Español
                <v-icon right large>mdi-file-pdf-box</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <RowSubHeader :title="'Why Contact Us'" />
          <p>
            Dr. Raquel Pino enjoys establishing long-lasting relationships with
            her patients and is ready to get started with you today. An open
            line of communication is key to having happy smile, start
            communicating with your dentist today.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import GMap from '@/components/GMap.vue';
import RowSubHeader from '@/components/RowSubHeader.vue';
import RowHeader from '@/components/RowHeader.vue';
import Captcha from '../components/Captcha.vue';
import { post } from '../services/util';

export default {
  name: 'ContactUs',
  components: {
    GMap,
    RowSubHeader,
    RowHeader,
    Captcha,
  },
  data: () => ({
    valid: false,
    firstname: '',
    firstnameRules: [
      (v) => !!v || 'First Name is required',
      (v) =>
        (v && v.length <= 20) || 'First Name must be less than 20 characters',
    ],
    lastname: '',
    lastnameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 20) || 'Last Name must be less than 20 characters',
    ],
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phonenumber: '',
    phonenumberRules: [
      (v) => !!v || 'Phone number is required',
      // (v) => /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/.test(v) || "Phone Number must be valid",
    ],
    comments: '',
    captcha_ver: true,
    captcha_token: '',
    snackbarSuccess: false,
    snackbarFail: false,
    connect: [
      {
        text: 'Facebook',
        route: 'https://www.facebook.com/rpinodental/',
        icon: 'mdi-facebook',
      },
      {
        text: 'Instagram',
        route: 'https://www.instagram.com/pinodental/',
        icon: 'mdi-instagram',
      },
      {
        text: 'Directions',
        route: 'https://goo.gl/maps/717TepTDaE4nbKQp8',
        icon: 'mdi-map-marker',
      },
    ],
  }),
  methods: {
    doEmail() {
      post(
        '/api/mail',
        {
          firstname: this.firstname,
          lastname: this.lastname,
          comments: this.comments,
          phonenumber: this.phonenumber,
          email: this.email,
        },
        { 'captcha-token': this.captcha_token }
      )
        .then(() => {
          this.firstname = '';
          this.lastname = '';
          this.comments = '';
          this.phonenumber = '';
          this.email = '';
          this.snackbarSuccess = true;
          this.captcha_ver = true;
        })
        .catch((e) => {
          this.snackbarFail = true;
          console.log(e);
        });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if (this.captcha_ver === true) {
        return false;
      }
      return this.valid;
    },
  },
};
</script>
