<template>
  <v-container class="my-10">
    <v-row justify="center">
      <v-col cols="10" class="d-flex flex-column text-center">
        <div class="text-h6 font-weight-bold primary--text mb-2">
          {{ pretext }}
        </div>
        <h2 class="text-h4 text-lg-h4 font-weight-bold darker--text my-2">
          {{ title }}
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-chip class="px-12" color="primary" x-small> </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'RowHeader',
  props: ['pretext', 'title'],
  data() {
    return {};
  },
};
</script>
