<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="4" v-for="item of choose" :key="item.title" class="d-flex flex-column align-center">
        <v-avatar color="primary" size="72" class="elevation-3 mx-auto">
          <v-icon dark x-large>
            {{ item.icon }}
          </v-icon>
        </v-avatar>
        <h5 class="my-6 text-h6 darker--text text-center">
          {{ item.title }}
        </h5>
        <p class="dark--text text-justify">
          {{ item.text }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ChoosePino',
  data: () => ({
    //connect bar
    choose: [
      {
        icon: 'mdi-toothbrush-electric',
        title: 'All Aspects of Dentistry',
        text: 'From simple yet critical preventive care to full mouth rehabilitation. Our purpose, at Pino Dental, is to provide a patient-centered practice that is educational, accommodating, thorough, and proactive with patient care. Pino Dental prides itself on being part of the community.',
      },
      {
        icon: 'mdi-map',
        title: 'Located in South Miami-Dade',
        text: 'Conveniently located at The Shoppes at Quail Roost, Pino Dental strives to deliver patient-centered care by providing accommodating hours and accepting several insurances.',
      },
      {
        icon: 'mdi-hand-heart',
        title: 'Long-lasting Relationship',
        text: 'Establishing a long-lasting relationship with each and every patient, that is founded in superior customer service, is key to ensuring practice success. By prioritizing patient education as a key part of treatment, we want to ensure that trust is established between patients and the care team before every procedure.',
      }
    ],
  }),
};
</script>
