import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AboutPinoDental from '../views/AboutPinoDental.vue'
import MeetDoctorRaquelPinoAndStaff from '../views/MeetDoctorRaquelPinoAndStaff.vue'
import TourTheOffice from '../views/TourTheOffice.vue'
import Services from '../views/Services.vue'
import AffordableSolutions from '../views/AffordableSolutions.vue'
import Testimonials from '../views/Testimonials.vue'
import ContactUs from '../views/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about/about-pino-dental',
    name: 'AboutPinoDental',
    component:AboutPinoDental
  },
  {
    path: '/about/meet-doctor-raquel-pino-and-staff',
    name: 'MeetDoctorRaquelPinoAndStaff',
    component:MeetDoctorRaquelPinoAndStaff
  },
  {
    path: '/about/tour-the-office',
    name: 'TourTheOffice',
    component:TourTheOffice
  },
  {
    path: '/services',
    name: 'Services',
    component:Services
  },
  {
    path: '/affordable-solutions',
    name: 'AffordableSolutions',
    component:AffordableSolutions
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component:Testimonials
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component:ContactUs
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
