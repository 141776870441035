<template>
  <div>
    <RowHeader :pretext="''" :title="'About Pino Dental'" />
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="6" md="6" order="2" order-md="1">
          <v-container>
            <v-row dense>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/about-04.jpeg')" aspect-ratio="1" />
              </v-col>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/smile-gallery-06.jpg')" aspect-ratio="1" />
              </v-col>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/smile-gallery-07.jpg')" aspect-ratio="1" />
              </v-col>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/smile-gallery-09.jpg')" aspect-ratio="1" />
              </v-col>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/smile-gallery-08.jpg')" aspect-ratio="1" />
              </v-col>
              <v-col class="d-flex child-flex" cols="6">
                <v-img :src="require('@/assets/smile-gallery-05.jpg')" aspect-ratio="1" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <p>
            Centrally located near the Quail Roost, South Miami Heights, and
            West Perrine neighborhoods, Dr. Raquel Pino and her staff will
            provide a wide range of different dental services, from child
            dentistry to adult full mouth reconstruction. When visiting our
            brand new, state-of-the-art dental practice, you can expect to
            receive the best.
          </p>
          <h5 class="my-6 text-h6 darker--text">The Pino Dental Difference</h5>
          <v-list dense>
            <v-list-item v-for="item of bullets" :key="item.text">
              <v-list-item-icon>
                <v-icon color="primary">mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <h4 class="darker--text text-h4 font-weight-bold my-6">
            Pino Dental
            <span class="primary--text">Mission Statement</span>
          </h4>
          <v-alert
            color="primary"
            dark
            icon="mdi-tooth-outline"
            border="left"
            prominent
            class="roboto-slab-button"
          >
            To provide the best quality dental care using the highest quality
            dental materials and implementing the latest peer-reviewed research
            techniques while ensuring a compassionate, comprehensive, courteous
            and patient-centered care environment.
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid pa-0>
      <v-row no-gutters class="light">
        <v-col>
          <v-container fill-height>
            <v-row align="center">
              <v-col class="px-12">
                <h4 class="darker--text text-h4 font-weight-bold mb-6">
                  Affordable <span class="primary--text">Solutions</span>
                </h4>
                <p class="text-justify dark--text">
                  We are here to help you get the dental treatment you deserve
                  at a price you can afford! Pino Dental accepts many insurances
                  to help cover your dental needs. In addition, we provide many
                  payment options, such as third party financing through
                  companies such as Lending Club and Care Credit, to make it
                  easy for you to achieve your best smile.
                </p>
                <v-list dense color="transparent">
                  <v-list-item v-for="item of asbullets" :key="item.text">
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check-bold</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ item.text }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-btn
                  outlined
                  rounded
                  x-large
                  color="link"
                  class="roboto-slab-button"
                  to="/affordable-solutions"
                >
                  More on Affordable Solutions
                  <v-icon right>mdi-hand-pointing-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="6">
          <img
            position="top right"
            height="500"
            :src="require('@/assets/banner-right.jpeg')"
          />
        </v-col>
      </v-row>
    </v-container>
    <RowHeader
      :pretext="'Notice the Details'"
      :title="'Why Choose Pino Dental?'"
    />
    <ChoosePino />
    <v-parallax dark :src="require('@/assets/banner-center.jpeg')">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 font-weight-bold mb-6">
            Let us give you the smile of your dreams!
          </h1>
          <h4 class="subheading mb-6">
            Pino Dental provides the highest quality dental treatments in South
            Florida.
          </h4>
          <v-btn
            outlined
            rounded
            x-large
            color="white"
            class="roboto-slab-button"
          >
            Request an Appointment
            <v-icon right>mdi-hand-pointing-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>
<script>
import RowHeader from '@/components/RowHeader.vue';
import ChoosePino from '@/components/ChoosePino.vue';

export default {
  name: 'Home',
  metaInfo: {
    title: 'About Pino Dental Dr. Raquel C. Pino DMD a Miami Dentist',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Your Experience at Pino Dental includes ceiling mounted smart HDTV with blue tooth head phones in every treatment room - fully stocked beverage bar.',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, 3-D CT Imaging, HD Intra-oral Camera, south miami dentist',
      },
    ],
  },
  components: {
    RowHeader,
    ChoosePino,
  },
  data: () => ({
    bullets: [
      {
        text: '3-D CT Imaging',
      },
      {
        text: 'Weighted Blankets',
      },
      {
        text: 'Ceiling Mounted Smart HDTV with Bluetooth Head Phones in Every Treatment Room',
      },
      {
        text: 'Free Wifi',
      },
      {
        text: 'HD Intra-oral Camera to Show You What the Dentist Sees During Your Exam',
      },
      {
        text: 'Private Treatment Rooms',
      },
      {
        text: 'Customizable Payment Plans for EVERY Budget',
      },
    ],
    asbullets: [
      {
        text: 'List of Accepted Insurances',
      },
      {
        text: 'Membership Program',
      },
      {
        text: 'Other Ways to Afford Treatment',
      },
    ],
  }),
};
</script>
