<template>
  <v-container fluid px-0>
    <RowHeader :pretext="''" :title="'Affordable Solutions'" />
    <v-row justify="center">
      <v-col cols="10">
        <p>
          Pino Dental understands that providing as many payment options as
          possible allows you to achieve your best smile. That is the reason we
          exhaust all options to maintain affordable prices and accept many
          forms of payment. By accepting most insurances, offering in-house
          payment plans, third-party financing, and having a membership plan,
          our goal is to make it easy to achieve the smile of your dreams.
        </p>
      </v-col>
    </v-row>
    <v-row class="light">
      <v-col cols="12" md="6">
        <div class="px-6">
          <h4 class="darker--text text-h4 font-weight-bold mb-6">
            Our <span class="primary--text">Dental Insurances</span>
          </h4>

          <p>
            Pino Dental accepts many insurances to help cover your dental needs.
            If your insurance is accepted, we will file all the proper claims
            for your convenience. A full list of accepted insurances has been
            listed below.
          </p>
        </div>
        <v-container>
          <v-row no-gutters>
            <v-col cols="6" v-for="(item, i) of insurances" :key="i">
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon color="primary">mdi-check-bold</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ item }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
        <div class="pa-6">
          <p>
            If you do not see your insurance listed here, please contact us for
            more information.
          </p>
          <v-btn block x-large text class="text-h6 font-weight-bold">
            <v-icon color="primary" left> mdi-phone </v-icon>
            (305) 709-1090
          </v-btn>
          <v-divider class="my-2"></v-divider>
          <v-btn
            block
            x-large
            text
            class="text-h6 font-weight-bold"
            href="sms:+17863846442?&body=I%20am%20sending%20a%20text%20message%20from%20PinoDental.com.%20Let%27s%20connect..."
          >
            <v-icon color="primary" left> mdi-message-text </v-icon>
            (786) 384-6442
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-img src="@/assets/banner-right.jpeg" height="100%" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="pl-6">
        <RowHeader
          :pretext="`Don’t have Dental Insurance?`"
          :title="'No Worries!'"
        />
        <v-card>
          <v-img src="@/assets/dental-slide-01.jpeg" max-height="200" />
          <v-card-text>
            <p>
              Pino Dental understands that some people do not have access to
              dental insurance. We are here to help you get the dental treatment
              you deserve at a price you can afford! With our in-house
              <strong class="font-italic"
                >Pino Fresh Smile membership program</strong
              >, you save up to 25% off our standard fees on all the dental care
              we offer.
            </p>
            <strong>For $200 per year, per adult(18+), you receive:</strong>
            <v-treeview v-model="tree" open-all :items="items" item-key="name">
              <template v-slot:prepend>
                <v-icon color="primary"> mdi-check-bold </v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9" md="6" class="pr-6">
        <RowHeader
          :pretext="'Other Ways You Can'"
          :title="'Afford Treatment'"
        />
        <v-card>
          <v-img
            src="@/assets/shutterstock_1064530364-scaled.jpeg"
            max-height="200"
          />
          <v-card-text>
            <strong>Third Party Financing</strong>
            <v-alert
              color="primary"
              dark
              icon="mdi-check-bold"
              border="left"
              prominent
            >
              Third party financing is a vehicle that allows monthly payment
              with little to no interest. We have financing available through
              third party companies such as Care Credit, Denefits, and Lending
              Club. If interested, a member of our staff will assist you in the
              application.
            </v-alert>
            <strong>Traditional Forms of Payment</strong>
            <v-alert
              color="primary"
              dark
              icon="mdi-check-bold"
              border="left"
              prominent
            >
              We exhaust all options to maintain affordable prices and accept
              many forms of payment such as all major credit cards, debit cards,
              cash, or money orders.
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-alert text color="secondary" class="ma-3 ma-lg-12">
          <h3 class="text-h5 text-center">
            For More Details Please Contact Us or Call Today:
          </h3>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn block text class="text-h6 font-weight-bold">
                  <v-icon color="secondary" left> mdi-email </v-icon>
                  info@pinodental.com
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn block text class="text-h6 font-weight-bold">
                  <v-icon color="secondary" left> mdi-phone </v-icon>
                  (305) 709-1090
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RowHeader from '@/components/RowHeader.vue';

export default {
  name: 'AffordableSolutions',
  metaInfo: {
    title:
      'Pino Fresh Smile membership program, Dental Insurance and Ways to Pay',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          "We understand some people don't have access to dental insurance. We are here to help you get the dental treatment you deserve at a price you can afford!",
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, dental insurance, dental insurance plans, dental insurance no waiting period, dental plans, 	dental insurance',
      },
    ],
  },
  components: {
    RowHeader,
  },
  data: () => ({
    insurances: [
      'AETNA PPO',
      'AMERITAS',
      'AMERIPLAN',
      'CAREINGTON',
      'CIGNA PPO',
      'DENTAQUEST ( SIMPLY MEDICARE )',
      'DENTEMAX',
      'GUARDIAN PPO',
      'IHC HEALTH SOLUTION PPO ( DENTEMAX)',
      'METLIFE PPO',
      'PRINCIPLE LIFE PPO',
      'BLUE CROSS AND BLUE SHIELD',
      'SUN LIFE/ ASSURANT PPO',
      'UNITED CONCORDIA',
      'UNITED HEALTHCARE PPO',
      'DELTA DENTAL PPO',
      'HUMANA PPO/ MEDICARE',
      'TRICARE',
      'ENVOLVE',
      'MUTUAL OF OMAHA',
      'FLORIDA COMBINED LIFE PPO',
      'FLORIDA BLUE PPO',
      'LINCOLN FINANCIAL GROUP',
      'UNUM ( DENTEMAX )',
      'LIBERTY ( MEDICARE )',
    ],

    tree: [],
    items: [
      {
        name: 'Two Complete Preventive Care visits per year, including:',
        children: [
          {
            name: 'Diagnostic Oral Evaluation',
          },
          {
            name: 'Complete Full Mouth X-Rays',
          },
          {
            name: 'Regular Cleaning OR $100 credit towards Deep Cleaning',
          },
        ],
      },
      {
        name: '25% off ALL other Dental Treatments',
      },
    ],
  }),
};
</script>
