<template>
  <v-container>
    <RowHeader :pretext="''" :title="'Tour The office'" />
    <v-row>
      <v-col>
        <v-carousel hide-delimiters>
          <v-carousel-item v-for="(item, i) in gallery" :key="i">
            <v-img
              :src="require('../assets/' + item.img)"
              height="500"
              contain
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import RowHeader from '@/components/RowHeader.vue';

export default {
  name: 'TourTheOffice',
  metaInfo: {
    title: 'Tour the Dental Office of Pino Dental',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Check out some photos of our facility, starting from the outside, take a tour of the office through the lobby and into each exam room.',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, dentist office near me, most recommended dentist near me, find a dentist, good dentists, find a new dentist',
      },
    ],
  },
  components: {
    RowHeader,
  },
  data: () => ({
    gallery: [
      {
        img: 'dental-office-tour-front.jpeg',
      },
      {
        img: 'dental-office-tour-front2.jpeg',
      },
      {
        img: 'dental-office-tour-hallway.jpeg',
      },
      {
        img: 'dental-office-tour-lobby.jpeg',
      },
      {
        img: 'dental-office-tour-lobby1.jpeg',
      },
      {
        img: 'dental-office-tour-lobby2.png',
      },
      {
        img: 'dental-office-tour-mural.jpeg',
      },
      {
        img: 'dental-office-tour-room.jpeg',
      },
      {
        img: 'dental-office-tour-room2.jpeg',
      },
    ],
  }),
};
</script>
