<template>
  <v-container fluid>
    <RowHeader :pretext="'What We Offer'" :title="'Dental Services'" />
    <v-row justify="center">
      <v-col cols="8">
        <p>
          Pino Dental is conveniently located in the Shoppes at Quail Roost.
          With plenty of parking, you can schedule your annual dental check up
          and do your routine shopping. Dr. Pino and her staff will provide a
          wide range of different dental services, from routine dental check up
          to full mouth reconstruction. Schedule today!
        </p>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="auto" v-for="item of services" :key="item.title">
        <v-card rounded elevation="5" height="100%">
          <v-img :src="item.img" max-height="200" class="white--text align-end">
            <div class="fill-height bottom-gradient">
              <v-card-title
                primary-title
                class="roboto-slab-button text-h5 font-weight-bold"
              >
                {{ item.title }}
              </v-card-title>
            </div>
          </v-img>
          <v-divider class="primary" />
          <v-list dense color="transparent">
            <v-list-item v-for="(service, i) of item.microservices" :key="i">
              <v-list-item-icon>
                <v-icon color="primary">mdi-check-bold</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="body-2">
                  {{ service }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <p>
          Dr. Pino’s philosophy of treating dental pathology is to be as
          conservative as possible while treating the issue correctly. If the
          tooth is healthy and nothing is wrong, there is no need to intervene.
          For example, many patients want to change their old “silver” fillings
          just because of the esthetics. While replacing it with a composite
          (white) filling will produce a better visual result, this way of
          thinking may lead to tooth sensitivity or other issues down the road.
          If after examination by Dr. Pino it is determined that the “silver”
          filling is intact and has no cracks or cavity, her recommendation
          would be not to replace, especially if the filling is in a posterior
          (back) tooth where it is not in the “esthetic zone”, which is the
          portion of your mouth typically visible to others.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <p>
          This conservative approach is rooted in the fact that every time a
          filling is replaced, tooth structure must be removed, potentially
          weakening the tooth. This is necessary in some circumstances when the
          filling is cracked, broken, there is visible decay, or the patient is
          developing symptoms and the filling must be replaced. However, if more
          than half the tooth is broken, decayed, or fractured, a filling alone
          will not suffice. In these cases, a crown is typically the next best
          option to preserving your tooth, where Dr. Pino can take steps to
          ensure the tooth is stable and strong enough to withstand the strong
          chewing forces of the mouth. In all cases, treatment options will be
          clearly discussed and evaluated with patients to ensure that the
          patient’s goals are addressed and that they feel confident about their
          customized treatment plan. To start your journey towards improving
          your oral health book your appointment with us today!
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.5) 0%,
    transparent 64px
  );
}
</style>
<script>
import RowHeader from '@/components/RowHeader.vue';

export default {
  name: 'Services',
  metaInfo: {
    title:
      'Dr. Pino provides a range dental services for adults or children in Miami.',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          ' Dr. Pino and her staff will provide a wide range of different dental services, from routine dental check up to full mouth reconstruction. Schedule today!',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, Cosmetic Dentistry, General Dentistry, Children Dentist, Dental Implants, Orthodontics, Dental Emergency, Periodontics, Dental Surgery, Crowns, Bridges',
      },
    ],
  },
  components: {
    RowHeader,
  },
  data: () => ({
    services: [
      {
        title: 'Cosmetic Dentistry',
        img: require('@/assets/services-01.jpg'),
        microservices: [
          'Orthodontics',
          'White Fillings',
          'Porcelain Veneers',
          'Teeth Whitening',
          'Inlays & Onlays',
          'Botox & Dermal Fillers',
        ],
      },
      {
        title: 'General Dentistry',
        img: require('@/assets/services-07.jpg'),
        microservices: [
          'Laughing (Nitrous) Gas',
          'Root Canal Therapy',
          'Nervous Patients',
          'Snoring & Sleep Apnea Solutions',
          'Nightguards & Mouthguards',
        ],
      },
      {
        title: "Children's Dentistry",
        img: require('@/assets/about-02.jpg'),

        microservices: [
          'Dental Emergencies',
          'Exams & Dental Cleanings',
          'Dental Sedation',
          'Digital X-Rays',
          'Fluoride Treatments',
          'Dental Restorations',
        ],
      },
      {
        title: 'Dental Implants',
        img: require('@/assets/services-04.jpg'),

        microservices: [
          'Same Day Implants',
          'Implant Bridges',
          'Dental Implants',
          'All on 4',
          'Implant Over Dentures',
        ],
      },
      {
        title: 'Orthodontics',
        img: require('@/assets/services-05.jpg'),

        microservices: ['Retainers', 'Invisalign', 'Invisible Braces'],
      },
      {
        title: 'Dental Emergency',
        img: require('@/assets/services-06.jpg'),

        microservices: [
          'Bleeding / Hemorrhage',
          'Serious infection',
          'Visible swelling in or around the mouth',
          'Severe pain',
          'Dental Trauma: Injuries as a result of a recent accident',
        ],
      },
      {
        title: 'Periodontics',
        img: require('@/assets/services-09.jpg'),

        microservices: [
          'Periodontal Therapy',
          'Deep Cleaning',
          'Laser Therapy',
          'Arrestin',
          'Antibiotic Application into the Gums',
          'Gingivitis',
          'Mild Periodontitis',
          'Moderate Periodontitis',
          'Severe Periodontitis',
        ],
      },
      {
        title: 'Dental Surgery',
        img: require('@/assets/services-080.jpg'),

        microservices: [
          'Teeth extraction',
          'Curettage',
          'Laser',
          'Other types of surgeries',
        ],
      },
      {
        title: 'Crowns & Bridges',
        img: require('@/assets/services-04.jpg'),
        microservices: ['Removable dentures', 'Fixed dentures', 'Veneers'],
      },
    ],
  }),
};
</script>
