<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="9" md="4">
        <v-img
          :src="require('@/assets/ptofile-close-transparent-home-bottom.png')"
          aspect-ratio="1"
          position="center top"
        />
        <v-list dense color="transparent">
          <v-subheader>Degrees </v-subheader>

          <v-list-item v-for="item of degrees" :key="item.text">
            <v-list-item-icon>
              <v-icon color="primary">mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list dense color="transparent">
          <v-subheader> Areas of Expertise </v-subheader>

          <v-list-item v-for="item of expertise" :key="item.text">
            <v-list-item-icon>
              <v-icon color="primary">mdi-check-bold</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="8">
        <h1 class="text-h5 font-weight-bold hidden-lg-and-up" >Dr. Raquel Pino DMD</h1>
        <v-toolbar flat>
          <h1 class="text-h5 font-weight-bold hidden-md-and-down">Dr. Raquel Pino DMD</h1>
          <v-spacer v-show="!$vuetify.breakpoint.mdAndDown"></v-spacer>

          <v-btn text class="text-h6 font-weight-bold">
            <v-icon color="primary" left> mdi-phone </v-icon>
            (305) 709-1090
          </v-btn>
    <v-spacer v-show="$vuetify.breakpoint.mdAndDown"></v-spacer>
          <v-btn
            icon
            color="primary"
            href="https://www.facebook.com/rpinodental/"
            target="_blank"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            href="https://www.instagram.com/pinodental/"
            target="_blank"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            href="https://goo.gl/maps/717TepTDaE4nbKQp8"
            target="_blank"
          >
            <v-icon>mdi-map-marker</v-icon>
          </v-btn>

          <v-btn icon color="primary" href="mailto:info@pinodental.com">
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </v-toolbar>
        
        <v-subheader class="primary--text font-weight-bold"
          >General and Cosmetic Dentist</v-subheader
        >
        <p class="text-justify dark--text">
          Born and raised in Miami, Dr. Raquel Pino graduated Cum Laude from
          Florida International University where she received her Bachelor of
          Science degree in Psychology. She earned her Doctor of Medical
          Dentistry Cum Laude from Boston University Goldman School of Dental
          Medicine. Upon graduation, Dr. Pino came back to practice dentistry in
          South Florida, ranging from community health centers to private
          practice, where she has treated patients from all ages and
          backgrounds. She is a member of the Academy of General Dentistry,
          American Dental Association, and Florida Dental Association.
        </p>
        <p class="text-justify dark--text">
          Dr. Pino is a General Dentist who is excited to treat patients in our
          practice. Her philosophy is to be compassionate and to provide
          individualized treatment to her patients so that they receive
          exceptional dental care. Dr. Pino’s down to earth demeanor along with
          great supporting team members will make your visit as comfortable as
          possible. Her philosophy is to ensure that patients are always well
          informed with all aspects of their oral health and treatment plan. She
          has a passion for cosmetic dentistry with a keen eye for detail. By
          attending continuing educations, seminars, and various study clubs,
          she is able to provide the most advanced dental treatments, including
          root canals, extractions, fillings, crowns, implants, Invisalign,
          Botox, fillers, and bridges. Dr. Pino loves to make her patients feel
          comfortable and is therefore Nitrous Oxide (laughing gas) and Oral
          Sedation certified.
        </p>
        <p class="text-justify dark--text">
          Dr. Pino is sensitive to different cultures and their traditions and
          is able to fluently converse in English and Spanish. An avid Disney
          fan and boating fan, in her free time, she enjoys spending her time
          vacationing to Disney World and boating with her husband David and two
          beautiful daughters, Ariella and Mila.
        </p>
        <v-list dense color="transparent">
          <v-list-item v-for="item of drpino" :key="item.text">
            <v-list-item-icon>
              <v-icon color="primary">mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.text }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <h3 class="text-h6">Dr. Pino and her Family</h3>
        <img
          position="center center"
          height="450"
          :src="require('@/assets/dr-pino-and-family.jpeg')"
        />
      </v-col>
    </v-row>
    <RowHeader :pretext="''" :title="'Our Staff'" />
    <v-row justify="center">
      <v-col cols="10" sm="6" md="4">
        <v-card height="100%">
          <v-img
            class="align-end"
            height="200px"
            :src="require('@/assets/ameli.jpg')"
          />
          <v-card-title class="roboto-slab-button">Amelie</v-card-title>
          <v-card-subtitle class="primary--text"
            >Front Desk &amp; Treatment Coordinator</v-card-subtitle
          >
          <v-card-text class="text--primary text-justify">
            <p>
              Meet Amelie, our front desk and treatment coordinator. Whether
              it’s organizing your treatment, explaining to you step by step how
              you can reach your oral health needs within your financial budget,
              or simply guiding you through check in and check out, Amelie is
              always enthusiastic and looking to please. Amelie is studying to
              get her Bachelors Degree in Business Administration.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" sm="6" md="4">
        <v-card height="100%">
          <v-img
            class="align-end"
            height="200px"
            :src="require('@/assets/maydolis.jpg')"
          />
          <v-card-title class="roboto-slab-button"
            >Maydolis Cabrera</v-card-title
          >
          <v-card-subtitle class="primary--text"
            >Office Manager</v-card-subtitle
          >
          <v-card-text class="text--primary text-justify">
            <p>
              Meet Maydolis Cabrera, also known as May. She is the first smiling
              face you will encounter when you walk in to Pino Dental. She will
              begin your journey to an anxiety free dental visit. From insurance
              to scheduling appointments,she will answer all your questions. May
              was born in Cuba and is currently studying Accounting at FIU.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" sm="6" md="4">
        <v-card height="100%">
          <v-img
            class="align-end"
            height="200px"
            :src="require('@/assets/nelvys-campos-head-dental-assistant.jpg')"
          />
          <v-card-title class="roboto-slab-button">Nelvys Campos</v-card-title>
          <v-card-subtitle class="primary--text"
            >Head Dental Assistant</v-card-subtitle
          >
          <v-card-text class="text--primary text-justify">
            <p>
              Meet Nelvys Campos, lead assistant at Pino Dental. As a practicing
              dentist in Cuba for 7 years, Nelvys has a wide and vast experience
              for everything dental. With a passion for educating patients,
              Nelvys will help answer your questions in great detail to ensure
              you understand and agree with your treatment plan.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" sm="6" md="4">
        <v-card height="100%">
          <v-img
            class="align-end"
            height="200px"
            :src="require('@/assets/leslie-de-la-hoz-dental-assistant.png')"
          />
          <v-card-title class="roboto-slab-button"
            >Leslie de la Hoz</v-card-title
          >
          <v-card-subtitle class="primary--text"
            >Dental Assistant</v-card-subtitle
          >
          <v-card-text class="text--primary text-justify">
            <p>
              Meet Leslie de la Hoz. She is our newest team member here at Pino
              Dental. She has been in the dental field for over 10 years. She
              studied Dentistry University of Cienfuegos. She is an essential
              member of the team that will enhance your clinical experience and
              guide you through anything you might need. In her spare time, she
              loves spending time with her husband and two Malinois puppies.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" sm="6" md="4">
        <v-card height="100%">
          <v-img
            class="align-end"
            height="200px"
            :src="require('@/assets/laura-martinez-dental-hygienist.png')"
          />
          <v-card-title class="roboto-slab-button">Laura Martinez</v-card-title>
          <v-card-subtitle class="primary--text"
            >Dental Hygienist</v-card-subtitle
          >
          <v-card-text class="text--primary text-justify">
            <p>
              Meet Laura Martínez, our dental hygienist. She has been in the
              dental field for over 20 years. She loves educating our patients
              on the importance of dental hygiene and health of their gums. In
              her free time she loves spending time with her 3 daughters and
              husband.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      
    </v-row>
  </v-container>
</template>
<script>
import RowHeader from '@/components/RowHeader.vue';

export default {
  name: 'MeetDoctorRaquelPinoAndStaff',
  metaInfo: {
    title: 'Meet the Doctor and Staff at Pino Dental',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Dr. Raquel Pino is a General Dentist with a passion for cosmetic dentistry, is Nitrous Oxide and Oral Sedation certified, and fluent in English & Spanish.',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'Pino Dental, dentist near me, dentist office, dentist appointment, Root Canals, Extractions, Fillings, Crowns, Implants, Invisalign, Botox, Fillers, Bridges, Veneers, Night Guards, Dentures, Whitening, Nitrous and Oral Sedation',
      },
    ],
  },
  components: {
    RowHeader,
  },
  data: () => ({
    drpino: [
      {
        text: 'Academy of General Dentistry',
      },
      {
        text: 'American Dental Association',
      },
      {
        text: 'Florida Dental Association',
      },
    ],
    degrees: [
      {
        text: 'Doctor of Medical Dentistry Cum Laude',
      },
      {
        text: 'Bachelor of Science degree in Psychology Cum Laude',
      },
    ],
    expertise: [
      { text: 'Root Canals' },
      { text: 'Extractions' },
      { text: 'Fillings' },
      { text: 'Crowns' },
      { text: 'Implants' },
      { text: 'Invisalign' },
      { text: 'Botox' },
      { text: 'Fillers' },
      { text: 'Bridges' },
      { text: 'Veneers' },
      { text: 'Night Guards' },
      { text: 'Dentures' },
      { text: 'Whitening' },
      { text: 'Nitrous and Oral Sedation' },
    ],
  }),
};
</script>
