<template>
  <v-container fluid pa-0 class="light">
    <v-row no-gutters justify="center">
      <v-col cols="12" md="6" class="d-flex flex-column align-center pa-6">
        <v-avatar color="transparent" size="80" tile>
          <v-icon dark size="144"> mdi-format-quote-close </v-icon>
        </v-avatar>
        <h5 class="text-h6 darker--text font-weight-light">
          What Patients are Saying
        </h5>
        <h4 class="darker--text text-h4 font-weight-bold">
          Patient <span class="primary--text">Testimonials</span>
        </h4>

        <v-carousel height="auto" hide-delimiters cycle show-arrows-on-hover>
          <v-carousel-item v-for="item of testimonials" :key="item.name">
            <v-container>
              <v-row justify="center">
                <v-col cols="10">
                  <v-card class="rounded-t-xl elevation-1">
                    <v-card-text>
                      <p class="text-justify body-1">{{ item.quote }}</p>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      {{ item.name }} -
                      <span class="font-weight-medium primary--text font-italic">{{
                        item.location
                      }}</span>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <v-img contain :src="require('@/assets/testimonials-right.jpg')" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'PatientTestimonials',
  data: () => ({
    testimonials: [
      {
        quote:
          'Dr Pino and her team are amazing. They explained in detail the different options I had with my dental problem. They tried to accommodate all my concerns and made me feel like family.',
        name: 'Rosi',
        location: 'The Roads, Miami, FL',
      },
      {
        quote:
          'Highly Recommend. This was the best dental office I’ve ever been to. The staff was so nice and helpful. Dr. Pino was incredible, explained everything, ensured I was comfortable.',
        name: 'Melissa',
        location: 'The Crossings, Miami, FL',
      },
      {
        quote:
          'I always go see Dr. Pino. She is the best. She takes her time explaining all treatment options and makes sure you understand everything.',
        name: 'Paulina',
        location: 'The Crossings, Miami, FL',
      }
    ],
  }),
};
</script>
