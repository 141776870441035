<template>
  <v-container fluid pa-0 class="light">
    <v-row no-gutters>
      <v-col class="pt-10">
        <v-container>
          <v-row no-gutters justify="center">
            <v-col cols="12" sm="8" md="4" class="px-12" order="1" order-sm="1">
              <v-img src="../assets/logo-v.png" contain height="150"></v-img>
              <p class="body-2 my-6">
                To receive email messages, simply provide us with your email
                address below.
              </p>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  outlined
                  placeholder="Your Email*"
                  v-model="email"
                  :rules="emailRules"
                >
                </v-text-field>
                <Captcha @verified="verified" />
                <v-btn
                  block
                  color="primary"
                  @click="doRegisterEmail"
                  :disabled="!validForm()"
                >
                  Subscribe <v-icon right>mdi-email</v-icon>
                </v-btn>
              </v-form>
            </v-col>
            <v-col cols="12" sm="5" md="4" class="px-12" order="3" order-sm="2">
              <h5 class="my-6 text-h6 darker--text">Site Menu</h5>
              <v-list nav dense color="transparent">
                <v-list-item
                  color="link"
                  v-for="item of sitemap"
                  :key="item.text"
                  :to="item.route"
                >
                  <v-list-item-icon>
                    <v-icon color="">
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="7" md="4" class="px-12" order="2" order-sm="3">
              <h5 class="my-6 text-h6 darker--text">Our Contact Information</h5>
              <v-list color="transparent" three-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-btn
                      fab
                      color="primary"
                      outlined
                      icon
                      to="https://goo.gl/maps/717TepTDaE4nbKQp8"
                      target="_blank"
                    >
                      <v-icon> mdi-map-marker </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>20161 SW 127th AVE</v-list-item-title>
                    <v-list-item-title>Miami, FL 33177</v-list-item-title>
                    <v-list-item-subtitle>Address</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-btn
                      fab
                      color="primary"
                      outlined
                      icon
                      to="tel:3057091090"
                    >
                      <v-icon> mdi-phone </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>(305) 709-1090</v-list-item-title>
                    <v-list-item-subtitle>Receptionist</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-btn
                      fab
                      color="primary"
                      outlined
                      icon
                      to="sms:+17863846442?&body=I%20am%20sending%20a%20text%20message%20from%20PinoDental.com.%20Let%27s%20connect..."
                    >
                      <v-icon> mdi-message-text </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>(786) 384-6442</v-list-item-title>
                    <v-list-item-subtitle>Text Message</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-btn
                      fab
                      color="primary"
                      outlined
                      icon
                      to="mailto:info@pinodental.com"
                    >
                      <v-icon> mdi-email </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>info@pinodental.com</v-list-item-title>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbarSuccess">
      <v-icon color="success" left>mdi-mailbox-up</v-icon>
      Your messege has been sent

      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarFail">
      <v-icon color="error" left>mdi-mailbox-up</v-icon>Error your messege not
      sent

      <template v-slot:action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style>
.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 6px !important;
}
</style>
<script>
import Captcha from '@/components/Captcha.vue';
import { post } from '@/services/util';

export default {
  name: 'Offers',
  data: () => ({
    sitemap: [
      {
        text: 'Home',
        route: '/',
        icon: 'mdi-home-variant-outline',
      },
      {
        text: 'About Pino Dental',
        route: '/about-pino-dental',
        icon: 'mdi-information-outline',
      },
      {
        text: 'Meet the Doctor and Staff',
        route: '/meet-doctor-raquel-pino-and-staff',
        icon: 'mdi-doctor',
      },
      {
        text: 'Tour the Office',
        route: '/tour-the-office',
        icon: 'mdi-chair-rolling',
      },
      {
        text: 'Services',
        route: '/services',
        icon: 'mdi-toothbrush-paste',
      },
      {
        text: 'Affordable Solutions',
        route: '/affordable-solutions',
        icon: 'mdi-cash',
      },
      {
        text: 'Testimonials',
        route: '/testimonials',
        icon: 'mdi-comment-quote-outline',
      },
      {
        text: 'Contact Us',
        route: '/contact-us',
        icon: 'mdi-forum',
      },
    ],
    valid: false,
    snackbarSuccess: false,
    snackbarFail: false,
    captcha_ver: true,
    captcha_token: '',
    email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
  }),
  components: {
    Captcha,
  },
  methods: {
    doRegisterEmail() {
      post(
        '/api/registermail',
        {
          email: this.email,
        },
        { 'captcha-token': this.captcha_token }
      )
        .then(() => {
          this.email = '';
          this.snackbarSuccess = true;
          this.captcha_ver = true;
        })
        .catch((e) => {
          this.snackbarFail = true;
          console.log(e);
        });
    },
    verified(response) {
      this.captcha_ver = false;
      this.captcha_token = response;
      this.validForm();
    },
    validForm() {
      // debugger;
      if (this.captcha_ver === true) {
        return false;
      }
      return this.valid;
    },
  },
};
</script>
